import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { AnimationContainer, ButtonContainer, Container, ContentContainer, ContentTextContainer, HeadingTitle, SmokeAnimation, StyledLottie } from './styles'
import { useContentSearchBySlugQuery, useCreateMeasureMutation, useGetActiveMeasuresQuery, useGetCompletedMeasuresQuery } from 'store';
import { useLocalizedText } from 'hooks/useLocalizedText';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { LottieRefCurrentProps } from 'lottie-react';
import { useAppDispatch } from 'hooks/redux';
import { setActiveOnboardingStep } from 'store/onboardingState/onboardingState';
import * as routes from 'router/routes';
import lottie from 'assets/animations/onboarding-page4-animation.json';
import lottieSmoke from 'assets/animations/smoke-page4-animation.json';
import RichText from '../../RichText';
import Button from 'components/UI/Button';
import { MeasureItem } from 'models';
import { useActiveLanguage } from 'hooks/useActiveLanguage';

type OnboardingState = {
  onboardingSlug: string;
} | null;


const OnboardingStep4: FC = () => {
  const slug = 'welcome-to-wellr';

  // States
  const [isReady, setIsReady] = useState<boolean>(false);
  const [hasOverviewMeasure, setHasOverviewMeasure] = useState<boolean>(false);
  const [isCreatingMeasure, setIsCreatingMeasure] = useState<boolean>(false);

  // Hooks
  const getText = useLocalizedText();
  const language = useActiveLanguage();
  const { data } = useContentSearchBySlugQuery({ slug: 'onboarding-step-4' });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [createMeasure] = useCreateMeasureMutation();
  
  // skip on isReady otherwise animation lags
  const getActiveMeasure = useGetActiveMeasuresQuery({
    language: language?.languageCode
  }, {
    skip: !language?.languageCode || isReady,
    refetchOnMountOrArgChange: true
  });
  const getCompletedMeasure = useGetCompletedMeasuresQuery({
    language: language?.languageCode
  }, {
    skip: !language?.languageCode || isReady,
    refetchOnMountOrArgChange: true
  });

  useEffect(() => {
    const checkMeasures = () => {
      const proposedMeasureFound = getCompletedMeasure.data?.some((item: MeasureItem) => item.slug.includes(slug)) ?? false;
      if (proposedMeasureFound) {
        setHasOverviewMeasure(true);
      } else {
        const activeMeasureFound = getActiveMeasure.data?.some((item: MeasureItem) => item.slug.includes(slug)) ?? false;
        setHasOverviewMeasure(activeMeasureFound);
      }
    };

    checkMeasures();
  }, [getActiveMeasure.data, getCompletedMeasure.data, slug]);


  const onClick = useCallback(
    (slug: string) =>
      async () => {
        setIsCreatingMeasure(true);
        let state: OnboardingState = null;
        try {
          if (!hasOverviewMeasure) {
            state = { onboardingSlug: slug };
            await createMeasure({ slug });
          }
          setIsReady(true);
          await new Promise<void>((resolve) => {
            setTimeout(async () => {
              navigate(`${routes.MEASURES}`, { state });
              resolve();
            }, 1500);
          });
        } finally {
          setIsCreatingMeasure(false);
        }
      },
    [createMeasure, hasOverviewMeasure, navigate]
  );

  useEffect(() => {
    dispatch(setActiveOnboardingStep(4));
  }, [dispatch]);


  const RocketAnimation: React.FC<{ isReady: boolean }> = ({ isReady }) => {
    const lottieRef = useRef<LottieRefCurrentProps>(null);
    const hasPlayedRef = useRef(false);

    useEffect(() => {
      if (lottieRef.current && !hasPlayedRef.current) {
        const anim = lottieRef.current;
        if (isReady) {
          anim.playSegments([45, 100], true);
        } else {
          anim.playSegments([30, 45], true);
        }
      }
    }, [isReady]);
    return (
      <AnimationContainer>
        <SmokeAnimation
          loop={!isReady}
          initialSegment={[35, 70]}
          animationData={lottieSmoke}
        />
        <StyledLottie
          lottieRef={lottieRef}
          loop={!isReady}
          animationData={lottie}
        />
      </AnimationContainer>
    );
  };

  if (!data?.length || !data[0]?.content) {
    return null;
  }
  const { content, title } = data[0];

  return (
    <Container>
      <RocketAnimation isReady={isReady} />
      <ContentContainer>
        <ContentTextContainer>
          <HeadingTitle>
            {getText(title)}
          </HeadingTitle>
          <RichText>{content}</RichText>
        </ContentTextContainer>
        <ButtonContainer>
          <Button
            size="large"
            color="white"
            background="blue"
            border="backgroundDark"
            rounded
            width='100%'
            onClick={() => {
              onClick(slug)();
            }}
            disabled={isCreatingMeasure}
          >
            <FormattedMessage
              id={'iamReadyButton'}
              defaultMessage="Next step"
              description="Button to next step"
            />
          </Button>
        </ButtonContainer>
      </ContentContainer>
    </Container>
  )
}

export default OnboardingStep4;