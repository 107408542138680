import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'utils/fetch';

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'Company',
    'CompanyCommunication',
    'Activity',
    'Content',
    'Contest',
    'ContestPreview',
    'Favorite',
    'StepGoal',
    'Health',
    'Quiz',
    'PulseQuiz',
    'Measure',
    'MeasureStep',
    'Membership',
    'User',
    'UserRegistrationCode',
    'AddableContestUsers',
    'ExerciseProgram',
    'DietRecipe',
    'CompanyCommunication',
    'UmbrellaCompanyCommunication',
    'Notifications',
    'CompanyInviteCodes',
    'ActivityToplist'
  ],
  endpoints: () => ({}),
});
