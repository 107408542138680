import React, { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { ButtonShowGoodTests, CategoryCircle, CategoryContainer, HeadingCategory, HeadingContainer } from './styles';
import { Gap } from 'styles';
import {
  localizationSelector,
  useCreateQuizMutation,
  useGetAvailablePulseQuizesQuery,
  useGetAvailableQuizesQuery,
  useGetHealthCategoriesQuery,
} from 'store';
import { useAppSelector } from 'hooks/redux';
import * as routes from 'router/routes';

import ListMenu from 'components/UI/ListMenu';
import Heading, { Tag } from 'components/UI/Heading';
import HealthCard from 'components/cards/TestCard';
import PulseCard from 'components/cards/PulseCard';
import Loader from 'components/UI/Loader';

import { CardList } from './styles';
import { getCategoryIcon } from 'utils/asset';
import { CategoryIcon, QuizItem } from 'models';
import Icon, { IconType } from 'components/UI/Icon';
import { motion } from 'framer-motion';

const Tests: FC = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const { language } = useAppSelector(localizationSelector);
  const pulseQuizes = useGetAvailablePulseQuizesQuery({
    language: language?.languageCode,
  });
  const quizes = useGetAvailableQuizesQuery({
    language: language?.languageCode,
    inclDeletedResults: false,
  });
  const [createQiuz, createResult] = useCreateQuizMutation();
  const { data: categories } = useGetHealthCategoriesQuery({ language: language?.languageCode });

  // States
  const [showApprovedTests, setShowApprovedTests] = useState(false);
  const [isHoveringShowApprovedTests, setIsHoveringShowApprovedTests] = useState(false);

  // Render category icon
  const renderIcon = useCallback(
    (icon: CategoryIcon, isActive: boolean = false) => {
      const img = getCategoryIcon(icon, isActive);
      return img ? <img src={img.src} alt={img.alt} /> : null;
    },
    []
  );

  // Actions
  const onQuizStartClick = useCallback(
    (slug: string) => async () => {
      await createQiuz({ language: language?.languageCode, slug });
      navigate(`${routes.QUIZ}/${slug}`);
    },
    [navigate, createQiuz, language]
  );
  const onQuizActiveClick = useCallback(
    () => navigate(routes.MEASURES),
    [navigate]
  );
  const onPulseQuizClick = useCallback(
    (id: string) => () => navigate(`${routes.PULSE_QUIZ}/${id}`),
    [navigate]
  );

  // Pulse quizes
  const pulseQuizContent = useMemo(() => {
    // No data
    if (!pulseQuizes.data?.length) {
      return null;
    }

    return (
      <Fragment>
        <Heading tag={Tag.H4}>
          <FormattedMessage
            id="pageTestsPulseTestsTitle"
            defaultMessage="Pulse tests"
            description="Section title for pulse tests"
          />
        </Heading>
        <CardList>
          {pulseQuizes.data.map((item) => (
            <PulseCard
              key={item.id}
              title={item.quizDefinition.title}
              summary={item.quizDefinition.summary}
              wellrPoints={item.quizDefinition.wellrPoints}
              date={item.endTime}
              onClick={onPulseQuizClick(item.id)}
              logoAssetId={item.companyLogoAssetId}
              buttonText={intl.formatMessage({
                id: 'takeTestButton',
                defaultMessage: 'Take test',
                description: 'Take test button text',
              })}
            />
          ))}
        </CardList>
      </Fragment>
    );
  }, [onPulseQuizClick, intl, pulseQuizes]);

  // Health quizes
  const healthQuizContent = useMemo(() => {
    if (quizes.isLoading || createResult.isLoading) {
      return <Loader color="blue" padding />;
    }

    if (!quizes.data) {
      return null;
    }

    const getLevelPriority = (level: string | undefined) => {
      switch (level) {
        case 'riskGroup': return 1;
        case 'changeNeeded': return 2;
        case 'approved': return 3;
        case 'good': return 3;
        default: return 4;
      }
    };

    const sortedQuizzes = [...quizes.data].sort((a, b) => {
      // Prioritize quizzes with quizShouldBeTaken first
      if (a.quizShouldBeTaken !== b.quizShouldBeTaken) {
        return a.quizShouldBeTaken ? -1 : 1;
      }

      // Sort by assessment reference level or by the lowest percentage in quizResults
      const aLevel = a.quizResults[0]?.assessment.assessmentReference?.level;
      const bLevel = b.quizResults[0]?.assessment.assessmentReference?.level;

      if (aLevel !== undefined && bLevel !== undefined) {
        return getLevelPriority(aLevel) - getLevelPriority(bLevel);
      }

      const aPercentage = a.quizResults.length > 0 ? a.quizResults[0].percentage : 0;
      const bPercentage = b.quizResults.length > 0 ? b.quizResults[0].percentage : 0;
      return aPercentage - bPercentage;
    });

     const sortedQuizzesWithApprovedResults = sortedQuizzes
      .filter((quiz) => {
        const level = quiz.quizResults[0]?.assessment.assessmentReference?.level;
        return (level === 'good' || level === 'approved') && !quiz.quizShouldBeTaken;
      })
      .sort((a, b) => {
        const aPercentage = a.quizResults.length > 0 ? a.quizResults[0].percentage : 0;
        const bPercentage = b.quizResults.length > 0 ? b.quizResults[0].percentage : 0;
        return aPercentage - bPercentage;
      });

    const sortedQuizzesWithUnapprovedResults = sortedQuizzes
      .filter((quiz) => {
        const level = quiz.quizResults[0]?.assessment.assessmentReference?.level;
        return (level === 'riskGroup' || level === 'changeNeeded' || quiz.quizShouldBeTaken);
      })
      .sort((a, b) => {
        const aPercentage = a.quizResults.length > 0 ? a.quizResults[0].percentage : 0;
        const bPercentage = b.quizResults.length > 0 ? b.quizResults[0].percentage : 0;
        return aPercentage - bPercentage;
      });

    const isAllTestsApproved = (sortedQuizzes.length === sortedQuizzesWithApprovedResults.length);

    const renderHealthCard = (item: QuizItem) => (
      <HealthCard
        key={item.quizSlug}
        category={item.quizDefinition.healthCategory}
        text={item.quizDefinition.summary}
        wellrPoints={item.quizDefinition.wellrPoints}
        results={item.quizShouldBeTaken ? [] : item.quizResults}
        onClick={onQuizStartClick(item.quizSlug)}
        onActiveClick={onQuizActiveClick}
        buttonText={intl.formatMessage({
          id: 'takeTestButton',
          defaultMessage: 'Take test',
        })}
        buttonActiveText={intl.formatMessage({
          id: 'implementMeasuresButton',
          defaultMessage: 'To the health plans',
        })}
      />
    );

    return (
      <>
        {sortedQuizzesWithUnapprovedResults.map(renderHealthCard)}
        {sortedQuizzesWithApprovedResults.length > 0 && !isAllTestsApproved &&
          <ButtonShowGoodTests
            onMouseEnter={() => setIsHoveringShowApprovedTests(true)}
            onMouseLeave={() => setIsHoveringShowApprovedTests(false)}
            onClick={() => setShowApprovedTests(!showApprovedTests)}>
            <FormattedMessage
              id={showApprovedTests ? "hideApprovedTestsButton" : "showApprovedTestsButton"}
              defaultMessage={showApprovedTests ? "Hide remaining tests" : "Show remaining tests"}
            />
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: showApprovedTests ? 90 : 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              <Icon
                type={IconType.Arrow}
                color="black"
                hoverColor="orange"
                isHovering={isHoveringShowApprovedTests}
              />
            </motion.div>
          </ButtonShowGoodTests>
        }
        {(showApprovedTests || isAllTestsApproved) && sortedQuizzesWithApprovedResults.map(renderHealthCard)}
      </>
    );
  }, [quizes.isLoading, quizes.data, createResult.isLoading, showApprovedTests, isHoveringShowApprovedTests, onQuizStartClick, onQuizActiveClick, intl]);

  const categoryCircles = useMemo(() => {
    const sortedQuizzes = [...(quizes.data ?? [])].sort((a, b) => {
      return a.quizShouldBeTaken === b.quizShouldBeTaken ? 0 : a.quizShouldBeTaken ? 1 : -1;
    });

    return sortedQuizzes.map((item) => (
      <CategoryCircle
        key={item.quizSlug}
        isActive={item.quizShouldBeTaken}
        isDisabled={false}
        onClick={() => {
          if (item.quizShouldBeTaken) {
            onQuizStartClick(item.quizSlug)();
          }
        }}
      >
        {renderIcon(item.quizDefinition.healthCategory!.icon, true)}
      </CategoryCircle>
    ));
  }, [onQuizStartClick, quizes.data, renderIcon]);

  const countQuizzes = useCallback((shouldBeTaken: boolean) => (
    (quizes.data ?? []).filter((quiz) => quiz.quizShouldBeTaken === shouldBeTaken).length
  ), [quizes.data]);

  const countTotalQuizzes = useMemo(() => (
    (quizes.data ?? []).length), [quizes.data]);

  const completedTestsContent = useMemo(() => {
    // Loading
    if (quizes.isLoading) {
      return <Loader color="blue" padding />;
    }
    if (!categories || !categories) {
      return null;
    }
    // No data
    if (!quizes.data) {
      return null;
    }

    return (
      <HeadingContainer>
        <HeadingCategory>
          <FormattedMessage
            id="pageTestsCompletedTestTitle"
            defaultMessage="Completed tests"
            description="Section title for completed test"
          />
        </HeadingCategory>
        <Heading>
          {countQuizzes(false)}
          <FormattedMessage
            id="of"
            defaultMessage="av"
            description="Section title for completed test"
          />
          {countTotalQuizzes}
        </Heading>
        <CategoryContainer>
          {categoryCircles}
        </CategoryContainer>

      </HeadingContainer>
    );
  }, [categories, categoryCircles, countQuizzes, countTotalQuizzes, quizes.data, quizes.isLoading]);

  return (
    <Fragment>
      {completedTestsContent}
      <ListMenu
        menu={[
          {
            id: 1,
            text: intl.formatMessage({
              id: 'menuTestHistory',
              defaultMessage: 'Test History',
              description: 'Menu item for test history',
            }),
            link: routes.TEST_HISTORY,
          },
          {
            id: 2,
            text: intl.formatMessage({
              id: 'menuWellrAnonymous',
              defaultMessage: 'Is Wellr anonymous?',
              description: 'Menu item for is Wellr anonymous',
            }),
            link: `${routes.INSPIRATION_ARTICLE}/is-wellr-anonymous`,
          },
        ]}
      />
      <Gap />
      {pulseQuizContent}
      <Heading tag={Tag.H4}>
        <FormattedMessage
          id="pageTestsHealthTestsTitle"
          defaultMessage="Health tests"
          description="Section title for health tests"
        />
      </Heading>
      <CardList>{healthQuizContent}</CardList>
    </Fragment>
  );
};

export default Tests;
