import Lottie from "lottie-react";
import styled from "styled-components";
import { CategoryContainer, ChartGrid } from "../HealthWheel/styles";
import { breakpoints } from "styles";

export const CategoryCircle = styled.div`
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.blue};
  border: 1px solid ${({ theme }) => theme.colors.borderLight};
  border-radius: 50%;
  cursor: pointer;
  opacity: 0.8;
  > * {
    margin: 0px !important;
    padding: 0px !important;
  }
`;

export const HeadingTitle = styled.h1`
  font-size: 1.625rem;
  font-weight: 700;
  margin: 16px 0;
  @media (max-width: ${breakpoints.s}px) {
    margin: 0px 0px 4px 6px;
  }
`;

export const ContentSectionContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
    >*{
    padding: 0px !important;
    margin-right: 8px !important;
  }
`;

export const CustomRichTextContainer = styled.div`
// margin: 16px 0px;
padding: 0px;
ul li {
  list-style-type: none;
  }
  ul {
  margin-top: 16px;}

  @media (max-width: ${breakpoints.s}px) {
  ul{
  margin-top: 4px;
  }
}
`;

export const AnimationContainer = styled.div`
  position: relative;
`;

export const StyledLottie = styled(Lottie)`
  flex: 1;
  display: flex;
  position: relative;
  z-index: 1;
  pointer-events: none;
  @media (max-width: ${breakpoints.xs}px) {
    max-height: 250px;
   }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  max-height: 960px;
  `;

export const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  padding: 16px;
  font-size: 1.1rem;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${breakpoints.xs}px) {
    font-size: 1rem;
    padding: 0 12px 0px 12px;
   }
`;

export const ContentTextContainer = styled.div`
  // padding: 0px 8px 72px 8px;

  // @media (max-width: ${breakpoints.s}px) {
  // padding: 0px 0px 72px 0px;
  // }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0;
  gap: 8px;
  margin-bottom: 10px;
`;

export const WheelContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  margin-top: 24px;
  flex-shrink: 1;

  ${ChartGrid} {
    grid-template-columns: repeat(1, 1fr);
  }
    ${CategoryContainer} {
    display: none;
  }

  @media (max-width: ${breakpoints.s}px) {
   padding: 0px;
  }
  @media (max-width: 390px) {
  height: 200px;
  width: 320px;
  }
`;

export const SmokeAnimation = styled(StyledLottie)`
  position: absolute;
  height: 100%;
  left: 0;
  z-index: 2;
`;

export const ButtonText = styled.div`
display: flex;
justify-content: center;
align-items: center;
  text-decoration: underline;
`;