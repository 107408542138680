import { InvitableMembership, MembershipSettings } from 'models';
import { baseApi } from '../baseApi';
import {
  endpoints,
  GetInvitableMembershipsWithStructureType,
} from './endpointDefinitions';

const membershipApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getMembershipSettings: builder.query<MembershipSettings, void>({
      query: () => '/membership/me',
      providesTags: ['Membership'],
    }),
    getInvitableMembershipsWithStructure: builder.query<
      GetInvitableMembershipsWithStructureType['result'],
      GetInvitableMembershipsWithStructureType['queryArgs']
    >({
      query: () => ({
        url: endpoints.getInvitableMembershipsWithStructure.path,
        method: endpoints.getInvitableMembershipsWithStructure.method,
      }),
      providesTags: ['Membership'],
    }),
    getInvitableMemberships: builder.query<InvitableMembership[], void>({
      query: () => '/membership/search/my-invitable-memberships',
      providesTags: ['Membership'],
    }),
    updateMembershipSettings: builder.mutation<
      MembershipSettings,
      MembershipSettings
    >({
      query: (body) => ({
        url: '/membership/me',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Membership', { type: 'User', id: 'USER_ME' }, 'ActivityToplist'],
    }),
  }),
});

export const {
  useGetMembershipSettingsQuery,
  useGetInvitableMembershipsQuery,
  useUpdateMembershipSettingsMutation,
  useGetInvitableMembershipsWithStructureQuery,
} = membershipApi;
